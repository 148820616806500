<template>
  <div>
    <HeaderBasic>
      <h2 class="page-title">Landlords</h2>
      <router-link to="/" :exact="true">Home</router-link>
      <span> / </span>
      <span class="current">Landlords</span>
    </HeaderBasic>
    <!-- Start About Section  -->
    <section class="about section custom-about2">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="wow animate__animated animate__fadeInLeft">
              <h2 class="section-title">Landlords</h2>
              <p>
                If you're thinking about letting your property, you've come to
                the right place, warm WELCOME TO Asamai Properties...
              </p>
              <h3>We are tried and tested</h3>
              <p>
                In the UK, letting is a very popular way of making money from
                property. Whether you are a first-time landlord with a single
                property, or a seasoned professional with a large portfolio, Our
                unparalleled experience means your property is in very safe
                hands and we will walk with you to give the best services that
                you will require from firm of estate agents.
              </p>
              <h3>What works for you?</h3>
              <p>
                Quite simply; we get it. As a landlord, you want to rent your
                property out as quickly as possible for the best price. You want
                to avoid empty periods and guarantee a regular income.
              </p>
              <p>
                At Asamai properties, if you don't want to be bothered with the
                day-to-day management of the property, we'll sort all that out
                too and all you will need is to see the agreed payments into
                your chosen bank.
              </p>
              <p>
                Letting property has been a core element of our business since
                we opened our doors for business – we understand the
                complexities.
              </p>
              <p>
                At Asamai Properties, every landlord and every property is
                different, so we will tailor our proposal to you. You want to be
                kept in the loop at every stage? We hear you. Prefer to leave it
                all to us while you get on with other things.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wow animate__animated animate__fadeInRight">
              <h3>We are With you all the way</h3>
              <p>
                There are lots of parts to the process of letting. We will
                advise and keep you in the know throughout our engagement. We
                will advise you at all stages, or just step in when you want us
                to.
              </p>
              <p>
                At Asamai Properties, in preparing the property, we will advise
                you on
              </p>
              <ul>
                <li>
                  Fixing up and decorating to suit the target tenant
                </li>
                <li>
                  Required safety checks
                </li>
                <li>
                  To furnish or not to furnish?
                </li>

                <li>
                  Full tenant checks
                </li>
                <li>
                  Preparing the tenancy agreement
                </li>
              </ul>
              <p>
                At Asamai Properties, we operate a liberal open door policy
                where we agree how much you do want us to be involved?
              </p>
              <ul>
                <li>
                  Want us to just market the property and find the right tenant?
                </li>
                <li>
                  Maybe we can offer a little more by collecting the rent every
                  month saving you the hassle?
                </li>
                <li>
                  Or, perhaps we can manage the property for you, ongoing? (No
                  late night calls when the boiler conks out)
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="featured-thumb wow animate__animated animate__fadeInLeft"
            >
              <img src="/assets/img/about/img2.jpg" alt="" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="wow animate__animated animate__fadeInRight">
              <p>
                We pride ourselves in the transparency and the manner in which
                we do business from start to finish of our engagement.
              </p>
              <p>
                Whether you are a vendor, a landlord, a prospective purchaser or
                a prospective tenant, at Asamai Properties, we possess the
                expertise to assist you with selling, letting or purchasing
                property. Due to our customised nature of business relationships
                with our customers, we have gained over the years an exceptional
                understanding of the property market in and around Leicester, we
                are the choice for all your property needs.
              </p>
              <p>
                We have built and continue to build a very good portfolio of
                clients over the years, with properties in all areas of
                Leicester and in the Midlands, we consider our clients to be the
                best assets of our business. Our clientele growth is
                pre-dominantly by referrals; a proof that our business model is
                truly of value to our clients.
              </p>
              <p>
                At Asamai properties, we value family interactions as we are a
                family based company we realise the important issues in finding
                your next property, this is why we take an extra care assigned
                approach to making your next move an easy and less hassle one,
                with a dedicated agent to help you each step of the way through.
              </p>
              <p>
                At Asamai Properties, we are committed to delivering the highest
                level of quality customer service. We take pride in our
                exceptional service and ensuring we give you the best possible
                service that you can never obtain from of our competitors.
              </p>
              <p>
                At Asamai Properties, we have a team of people who are
                enthusiastic, knowledgeable, determined and willing to give
                their all to make sure you receive best possible outcome in any
                of your requirements.
              </p>
              <p>
                Why not come and see us for your next property venture, after
                all, if you don't try, you'll never know....
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Section  -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  components: {
    HeaderBasic: () => import("./HeaderBasic")
  },
  mounted() {
    var wow = new WOW({
      live: false
    });
    wow.init();
  }
};
</script>
